// import React, { useEffect } from 'react';
// import SecondResultScreenComponent from '../../Student/ExamResult/SecondScreen';
// // import ThridScreenComponent from './ThridScreen';
// export default function FirstScreenResult(props) {
//   const {
//     ExamData,
//     setReload,
//     choose, setChoose,
//     AnswerArray, setAnswerArray,
//     ResultData, Rank, StudentDetails, QuestionsDataValue
//   } = props;
//   const [QuestionsSolved, setQuestionsSolved] = React.useState(0);
//   const [correctAnswer, setcorrectAnswer] = React.useState(0);
//   const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(false);
//   const [ArrayIndex, setArrayIndex] = React.useState("");
//   const [QuestionsData, setQuestionsData] = React.useState(
//     // JSON.parse(ExamData.meta.exam_question_block_text)
//     QuestionsDataValue
//   );
//   const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
//   const [QuestionsEditData, setQuestionsEditData] = React.useState(
//     QuestionsDataValue["item-0"]
//     // JSON.parse(ExamData.meta.exam_question_block_text)["item-0"]
//   );
//   const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");

//   const CalculateQuestionSolved = () => {
//     var correct = 0;
//     var solved = 0;
//     for (let i = 0; i < AnswerArray.length; i++) {
//       if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen) {
//         correct++
//       }
//       if (AnswerArray[i].solved == 1)
//         solved++
//       setQuestionsSolved(solved)
//       setcorrectAnswer(correct)
//     }
//   }
//   useEffect(() => {
//     CalculateQuestionSolved()
//   }, [])
//   return (
//     <div style={{ border: "1px solid #aaa", padding: "30px", }}>
//       {/* Questions Solved: {QuestionsSolved} */}
//       <h1>{ExamData.title.rendered}</h1>
//       {/* <b>Exam Date: {ResultData.meta.exam_date_results}</b> */}
//       <div style={{ padding: "10px" }}>
//         <span style={{
//           border: "2px solid black",
//           margin: "10px", width: "20px", height: "20px", backgroundColor: "#66fc03"
//         }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Correct_Answer
//         <span style={{
//           border: "2px solid black",
//           margin: "10px", width: "20px", height: "20px", backgroundColor: "#f00"
//         }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Wrong_Answer
//         <span style={{
//           border: "2px solid black",
//           margin: "10px", width: "20px", height: "20px", backgroundColor: "#f7e686"
//         }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Unsolved_Question

//       </div>

//       {
//         QuestionsEditMode ?
//           <div >
//             <div className="row center row-data">
//               <div className="col-md-6 row center" >
//                 {Object.keys(QuestionsData).map((item, index) => {
//                   if (index < 20)
//                     return (
//                       <div key={index} className="col-2 m-1 p-2 center"
//                         style={{
//                           backgroundColor: !AnswerArray[index].solved == 1 ?
//                             "#f7e686" :
//                             AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
//                               "#66fc03" :
//                               "#f00"
//                           ,
//                           fontWeight: QuestionsEditKey == item ? "bold" : "normal",
//                           border: "2px solid black",
//                           cursor: 'pointer'
//                         }}
//                         onClick={() => {
//                           setQuestionsEditData(QuestionsData[item])
//                           setQuestionsEditKey(item)
//                           setQuestionsEditMode(false)
//                           setArrayIndex(index)
//                         }}
//                       >
//                         {QuestionsData[item].question_number}
//                       </div>
//                     )
//                 })}
//               </div>
//               <div className="col-md-6 row">
//                 {Object.keys(QuestionsData).map((item, index) => {
//                   if (index >= 20)
//                     return (
//                       <div key={index} className="col-2 m-1 p-2 center"
//                         style={{
//                           backgroundColor: !AnswerArray[index].solved == 1 ?
//                             "#f7e686" :
//                             AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
//                               "#66fc03" :
//                               "#f00"
//                           ,
//                           fontWeight: QuestionsEditKey == item ? "bold" : "normal",
//                           border: "2px solid black",
//                           cursor: 'pointer'
//                         }}
//                         onClick={() => {
//                           setQuestionsEditData(QuestionsData[item])
//                           setQuestionsEditKey(item)
//                           setQuestionsEditMode(false)
//                           setArrayIndex(index)
//                         }}
//                       >
//                         {QuestionsData[item].question_number}
//                       </div>
//                     )
//                 })}
//               </div>
//               {/* <div className="row">
//             <div className="col-lg-4 col-12" />
//             <div className="col-lg-4 col-12 center">
//               <button className="sign-in-button-1 col-lg-8 col-7"
//                 onClick={() => {
//                   setChoose(2)
//                   // setTimingStarted(true)
//                   // SubmitExam()
//                 }}
//               >
//                 Submit and Finish Exam
//               </button>
//             </div>
//           </div> */}

//             </div>
//             <div className="row row-data">
//               <div className="col-8">Total Questions</div>
//               <div className="col-4">{Object.keys(QuestionsData).length}</div>
//             </div>
//             <div className="row row-data">
//               <div className="col-8">Total Attempt</div>
//               <div className="col-4">{QuestionsSolved}</div>
//             </div>
//             <div className="row row-data">
//               <div className="col-8">Total Correct</div>
//               <div className="col-4">{correctAnswer}</div>
//             </div>
//             <div className="row row-data">
//               <div className="col-8">Percentage Obtained</div>
//               <div className="col-4">{(correctAnswer / AnswerArray.length * 100).toFixed(2)}%</div>
//             </div>
//             <div className="row row-data">
//               <div className="col-8">Rank</div>
//               <div className="col-4">{Rank}</div>
//             </div>
//             {/* {StudentDetails.length !== 0 &&
//               <>
//                 <div className="row row-data">
//                   <div className="col-8">Student Name</div>
//                   <div className="col-4">{StudentDetails[0].meta.user_name}</div>
//                 </div>
//                 <div className="row row-data">
//                   <div className="col-8">Email</div>
//                   <div className="col-4">{StudentDetails[0].meta.user_email}</div>
//                 </div>
//                 <div className="row row-data">
//                   <div className="col-8">Phone number</div>
//                   <div className="col-4">{StudentDetails[0].meta.user_phone_number}</div>
//                 </div>
//               </>
//             } */}
//           </div>
//           :
//           <div>
//             {!QuestionsEditMode1 ?
//               <SecondResultScreenComponent
//                 keyValue={QuestionsEditKey}
//                 oneQuestionData={QuestionsEditData}
//                 AllQuestionData={QuestionsData}
//                 setAllQuestionData={setQuestionsData}
//                 AnswerArray={AnswerArray}
//                 ArrayIndex={ArrayIndex}
//                 setQuestionsSolved={setQuestionsSolved}
//                 QuestionsSolved={QuestionsSolved}
//               />
//               :
//               <div style={{ minHeight: "50vh" }}>
//               </div>
//             }

//             <div className="row mx-5 mt-3">
//               <div className="col-md-4">
//                 {ArrayIndex !== 0 &&
//                   <div className="col-12 previous-question" style={{ cursor: "pointer" }}
//                     onClick={() => {
//                       setQuestionsEditMode1(true)
//                       setTimeout(() => {
//                         setQuestionsEditMode1(false)
//                       }, 0)
//                       setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex - 1]])
//                       setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex - 1])
//                       setArrayIndex(ArrayIndex - 1)
//                     }}
//                   >
//                     <button className="sign-in-button-1 shadow">
//                       <i className="fa fa-chevron-left" />&nbsp; Previous Question
//                     </button>
//                   </div>
//                 }
//               </div>
//               <div className="col-md-4 " style={{ textAlign: 'center', cursor: "pointer" }}
//                 onClick={() => {
//                   setQuestionsEditMode(true)
//                 }}
//               >
//                 <button className="sign-in-button-1 shadow">
//                   <i className="fa fa-list" />&nbsp;   Question List
//                 </button>
//               </div>
//               {ArrayIndex !== Object.keys(QuestionsData).length - 1 &&
//                 <div className="col-md-4 question-list" style={{ cursor: "pointer" }}
//                   onClick={() => {
//                     setQuestionsEditMode1(true)
//                     setTimeout(() => {
//                       setQuestionsEditMode1(false)
//                     }, 0)
//                     setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
//                     setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex + 1])
//                     setArrayIndex(ArrayIndex + 1)
//                   }
//                   }
//                 >
//                   <button className="sign-in-button-1 shadow">
//                     Next Question&nbsp;  <i className="fa fa-chevron-right" />
//                   </button>
//                 </div>

//               }
//             </div>
//           </div>
//       }
//     </div >
//   )
// }



import React, { useEffect } from 'react';
import SecondResultScreenComponent from '../../Student/ExamResult/SecondScreen';
// import ThridScreenComponent from './ThridScreen';
export default function FirstScreenResult(props) {
  const {
    ExamData,
    setReload,
    choose, setChoose,
    AnswerArray, setAnswerArray,
    ResultData, Rank, StudentDetails, QuestionsDataValue
  } = props;
  const [QuestionsSolved, setQuestionsSolved] = React.useState(0);
  const [correctAnswer, setcorrectAnswer] = React.useState(0);
  const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(false);
  const [ArrayIndex, setArrayIndex] = React.useState("");
  const [QuestionsData, setQuestionsData] = React.useState(
    // JSON.parse(ExamData.meta.exam_question_block_text)
    QuestionsDataValue
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(
    QuestionsDataValue["item-0"]
    // JSON.parse(ExamData.meta.exam_question_block_text)["item-0"]
  );
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");

  const CalculateQuestionSolved = () => {
    var correct = 0;
    var solved = 0;
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen) {
        correct++
      }
      if (AnswerArray[i].solved == 1)
        solved++
      setQuestionsSolved(solved)
      setcorrectAnswer(correct)
    }
  }
  useEffect(() => {
    CalculateQuestionSolved()
  }, [])
  return (
    <div
      style={{ height: "95vh", overflowY: "scroll" }}
    >
      {/* Questions Solved: {QuestionsSolved} */}
      <h1>{ExamData.title.rendered}</h1>
      {/* <b>Exam Date: {ResultData.meta.exam_date_results}</b> */}
      <div style={{ padding: "10px" }} className='d-sm-block d-none'>
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#66fc03"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Correct_Answer
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#f00"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Wrong_Answer
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#f7e686"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Unsolved_Question

      </div>
      <div style={{ padding: "10px" }} className='d-sm-none d-block'>
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#66fc03"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Correct_Answer<br />
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#f00"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Wrong_Answer<br />
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#f7e686"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Unsolved_Question

      </div>

      {
        QuestionsEditMode ?
          <div >
            {/* <div className="row center row-data">
              <div className="col-md-6 row center" >
                {Object.keys(QuestionsData).map((item, index) => {
                  if (index < 20)
                    return (
                      <div key={index} className="col-2 m-1 p-2 center"
                        style={{
                          backgroundColor: !AnswerArray[index].solved == 1 ?
                            "#f7e686" :
                            AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
                              "#66fc03" :
                              "#f00"
                          ,
                          fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                          border: "2px solid black",
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setQuestionsEditData(QuestionsData[item])
                          setQuestionsEditKey(item)
                          setQuestionsEditMode(false)
                          setArrayIndex(index)
                        }}
                      >
                        {QuestionsData[item].question_number}
                      </div>
                    )
                })}
              </div>
              <div className="col-md-6 row">
                {Object.keys(QuestionsData).map((item, index) => {
                  if (index >= 20)
                    return (
                      <div key={index} className="col-2 m-1 p-2 center"
                        style={{
                          backgroundColor: !AnswerArray[index].solved == 1 ?
                            "#f7e686" :
                            AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
                              "#66fc03" :
                              "#f00"
                          ,
                          fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                          border: "2px solid black",
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setQuestionsEditData(QuestionsData[item])
                          setQuestionsEditKey(item)
                          setQuestionsEditMode(false)
                          setArrayIndex(index)
                        }}
                      >
                        {QuestionsData[item].question_number}
                      </div>
                    )
                })}
              </div>
            </div> */}
            <div className="row row-data">
              <div className="col-8">Total Questions</div>
              <div className="col-4">{Object.keys(QuestionsData).length}</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Total Attempt</div>
              <div className="col-4">{QuestionsSolved}</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Total Correct</div>
              <div className="col-4">{correctAnswer}</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Percentage Obtained</div>
              <div className="col-4">{(correctAnswer / AnswerArray.length * 100).toFixed(2)}%</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Rank</div>
              <div className="col-4">{Rank}</div>
            </div>
            {/* {StudentDetails.length !== 0 &&
              <>
                <div className="row row-data">
                  <div className="col-8">Student Name</div>
                  <div className="col-4">{StudentDetails[0].meta.user_name}</div>
                </div>
                <div className="row row-data">
                  <div className="col-8">Email</div>
                  <div className="col-4">{StudentDetails[0].meta.user_email}</div>
                </div>
                <div className="row row-data">
                  <div className="col-8">Phone number</div>
                  <div className="col-4">{StudentDetails[0].meta.user_phone_number}</div>
                </div>
              </>
            } */}
            {AnswerArray.map((item, index) => (
              <section key={index}>
                {/* {JSON.stringify(QuestionsData[`item-${index}`])}
                {JSON.stringify(item)} */}
                <SecondResultScreenComponent
                  oneQuestionData={QuestionsData[`item-${index}`]}
                  AnswerArray={AnswerArray}
                  ArrayIndex={index}
                />

              </section>
            ))}
          </div>
          :
          <div>
            {!QuestionsEditMode1 ?
              <SecondResultScreenComponent
                keyValue={QuestionsEditKey}
                oneQuestionData={QuestionsEditData}
                AllQuestionData={QuestionsData}
                setAllQuestionData={setQuestionsData}
                AnswerArray={AnswerArray}
                ArrayIndex={ArrayIndex}
                setQuestionsSolved={setQuestionsSolved}
                QuestionsSolved={QuestionsSolved}
              />
              :
              <div style={{ minHeight: "50vh" }}>
              </div>
            }

            <div className="row mx-5 mt-3">
              <div className="col-md-4">
                {ArrayIndex !== 0 &&
                  <div className="col-12 previous-question" style={{ cursor: "pointer" }}
                    onClick={() => {
                      setQuestionsEditMode1(true)
                      setTimeout(() => {
                        setQuestionsEditMode1(false)
                      }, 0)
                      setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex - 1]])
                      setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex - 1])
                      setArrayIndex(ArrayIndex - 1)
                    }}
                  >
                    <button className="sign-in-button-1 shadow">
                      <i className="fa fa-chevron-left" />&nbsp; Previous Question
                    </button>
                  </div>
                }
              </div>
              <div className="col-md-4 " style={{ textAlign: 'center', cursor: "pointer" }}
                onClick={() => {
                  setQuestionsEditMode(true)
                }}
              >
                <button className="sign-in-button-1 shadow">
                  <i className="fa fa-list" />&nbsp;   Question List
                </button>
              </div>
              {ArrayIndex !== Object.keys(QuestionsData).length - 1 &&
                <div className="col-md-4 question-list" style={{ cursor: "pointer" }}
                  onClick={() => {
                    setQuestionsEditMode1(true)
                    setTimeout(() => {
                      setQuestionsEditMode1(false)
                    }, 0)
                    setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
                    setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex + 1])
                    setArrayIndex(ArrayIndex + 1)
                  }
                  }
                >
                  <button className="sign-in-button-1 shadow">
                    Next Question&nbsp;  <i className="fa fa-chevron-right" />
                  </button>
                </div>

              }
            </div>
          </div>
      }
    </div >
  )
}
