import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import FileUpload from "../../../FileUpload";
import ImageUpload from "../../../ImageUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [BatchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [BatchStartDate, setBatchStartDate] = React.useState(FetchData.meta.batch_start_date)
  const [BatchEndDate, setBatchEndDate] = React.useState(FetchData.meta.batch_end_date)
  const [BatchStatus, setBatchStatus] = React.useState(FetchData.meta.batch_status)
  const [BatchPrice, setBatchPrice] = React.useState(FetchData.meta.batch_price)
  const [BatchDiscount, setBatchDiscount] = React.useState(FetchData.meta.batch_discount)
  const [BatchExam, setBatchExam] = React.useState(JSON.parse(FetchData.meta.batch_exam_list))
  const [BatchExamCache, setBatchExamCache] = React.useState(JSON.parse(FetchData.meta.batch_exam_list))
  const [BatchExamID, setBatchExamID] = React.useState(JSON.parse(FetchData.meta.batch_exam_id_list))
  const [BatchExamIDCache, setBatchExamIDCache] = React.useState(JSON.parse(FetchData.meta.batch_exam_id_list))
  const [BatchExamResetPrice, setBatchExamResetPrice] = React.useState(FetchData.meta.exam_reset_price)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.batch_image)
  const [choose, setChoose] = React.useState(0)

  const [AddNew, setAddNew] = React.useState(false)
  const [EditExamData, setEditExamData] = React.useState({})
  const [ExamArray, setExamArray] = React.useState({})
  const [ExamArray1, setExamArray1] = React.useState({})

  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")


  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [BatchAllowSell, setBatchAllowSell] = React.useState(FetchData.meta.batch_allow_buy)
  const [InstituteID, setInstituteID] = React.useState(FetchData.meta.batch_institute_id)
  const [InstituteName, setInstituteName] = React.useState(FetchData.meta.batch_institute_name)
  const [InstituteModal, setInstituteModal] = React.useState(false)
  const [InstituteList, setInstituteList] = React.useState([])
  const [CategoryArray, setCategoryArray] = React.useState([])

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "batch_name": Title,
        "batch_start_date": BatchStartDate,
        "batch_end_date": BatchEndDate,
        "batch_status": BatchStatus,
        "exam_reset_price": BatchExamResetPrice,
        "batch_price": BatchPrice,
        "batch_discount": BatchDiscount,
        "batch_exam_list": JSON.stringify(BatchExam),
        "batch_exam_id_list": JSON.stringify(BatchExamID),
        "batch_institute_name": InstituteName,
        "batch_institute_id": `${InstituteID}`,
        "batch_allow_buy": BatchAllowSell
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_exam_packages)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Batch Added Successfully")
          history.push(`${slugs.exam_packages_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_exam_packages, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }

  const GetAllProjects = (params) => {
    setReload(true)
    AdsGETApiAuth(params, slugs.db_slug_paid_exam)
      .then((res) => {
        setExamArray(res.data)
        setExamArray1(res.data)
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta.exam_time,meta.featured_image,meta.exam_type,meta.category_id"
    })
    FetchCategoryList()
    // InstituteListFetch()
  }, [])

  const InstituteListFetch = () => {
    AdsGETApiAuth(
      {
        per_page: 100,
        _fields: "id,title,slug,date,meta.institute_name,meta.institute_address,meta.institute_email,meta.institute_phone_number_1"
      },
      slugs.db_slug_institute
    )
      .then((res) => {
        setInstituteList(res.data)
      })
      .catch(e => {
      })
  }

  const MappingExam = ({ item }) => {
    const EnrollmentSearch = Object.values(BatchExamCache).filter(a => a.exam_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    return (
      <>
        <td>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
            BatchExamIDCache.push(item.id)
          }
        }}
        >Choose</td>
      </>
    )
  }

  const FetchCategoryList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,alug,title,meta" },
      slugs.db_slug_category
    )
      .then((res) => {
        setCategoryArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Batch Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Batch Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch Start Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchStartDate} placeholder="Start Date"
                    onChange={(event) => { setBatchStartDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch End Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchEndDate} placeholder="Batch End Date"
                    onChange={(event) => { setBatchEndDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch Price</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchPrice} placeholder="Batch Price"
                    onChange={(event) => { setBatchPrice(event.target.value) }}
                  />
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Batch Discount</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchDiscount} placeholder="Batch Discount"
                    onChange={(event) => { setBatchDiscount(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Batch Status</td>
                <td className="col-6">
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Running"} />
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Ended"} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Batch
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Batch Exam List</center></h4>

          <table>
            <tbody>
              <tr>
                <td>Exam ID</td>
                <td>Exam Name</td>
                <td>Exam Type</td>
              </tr>

              {BatchExam.map((item, index) => (
                <tr key={index}>
                  <td>{item.exam_id}</td>
                  <td>{item.name}</td>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setBatchExamIndex(index)
                    setBatchExamDeleteModal(true)
                    // BatchExam.splice(index, 1)
                  }}>Delete</td>
                </tr>
              ))}
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => {
                  setAddNew(true)
                }}>Add New</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      {BatchExamDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchExamDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Exam?
                <table>
                  <tbody>
                    <tr>
                      <td>Exam Name : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].exam_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        BatchExam.splice(BatchExamDeleteIndex, 1)
                        BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {AddNew &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", borderRadius: "10px", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNew(false)
                    setChoose(0)
                  }}
                >X</div>
                <div className="col-md-6 row">
                  <div className="col-1" />
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  {/* {choose == 0 ?
                    <table>
                      <tbody>
                        <tr>
                          <td>Category ID</td>
                          <td>Category Name</td>
                          <td>Choose</td>
                        </tr>
                        {CategoryArray.map((item, index) => (
                          <tr key={index} style={{ cursor: "pointer" }}
                            onClick={() => {
                              setChoose(1)
                              setExamArray(ExamArray1.filter(a => a.meta.category_id == item.id))
                            }}
                          >
                            <td> {item.id}</td>
                            <td> {item.title.rendered}</td>
                            <td> Choose</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    : */}
                    <table>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>Exam ID</td>
                          <td>Exam Name</td>
                          <td>Choose</td>
                        </tr>
                        {ExamArray.map((item, index) => (
                          <tr key={index}>
                            <MappingExam item={item} />
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  {/* } */}
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExamCache(BatchExam)
                          setBatchExamIDCache(BatchExamID)
                          setAddNew(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExam(BatchExamCache)
                          setBatchExamID(BatchExamIDCache)
                          setAddNew(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      }

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}